var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c(
          "div",
          {
            class: [
              _vm.scenarioViewGroup === "L"
                ? "col-12"
                : "col-xs-12 col-sm-6 col-md-4 col-lg-4",
              "scenario-list",
            ],
          },
          [
            _c(
              "c-table",
              {
                ref: "table",
                attrs: {
                  title: "물질별 위험도 추정",
                  columns: _vm.grid.columns,
                  gridHeight: _vm.setheight,
                  data: _vm.process.charmEstimationScenarios,
                  merge:
                    _vm.scenarioViewGroup === "L" ||
                    _vm.scenarioViewGroup === "C"
                      ? _vm.grid.merge
                      : [],
                  filtering: false,
                  customTrClass: _vm.setTrClass,
                  columnSetting: false,
                  usePaging: false,
                  isExcelDown: _vm.scenarioViewGroup === "L",
                  isFullScreen: _vm.scenarioViewGroup === "L",
                  editable: _vm.editable && !_vm.disabled,
                },
                on: { "table-data-change": _vm.tableDataChange },
                scopedSlots: _vm._u([
                  {
                    key: "suffixTitle",
                    fn: function () {
                      return [
                        _c(
                          "q-btn-group",
                          {
                            staticClass: "scenario-view-group",
                            attrs: { outline: "" },
                          },
                          [
                            _c(
                              "q-btn",
                              {
                                attrs: {
                                  outline: _vm.scenarioViewGroup !== "L",
                                  color:
                                    _vm.scenarioViewGroup === "L"
                                      ? "indigo"
                                      : "grey",
                                  size: "11px",
                                  label: "",
                                  icon: "view_list",
                                },
                                on: { click: _vm.listView },
                              },
                              [
                                _c("q-tooltip", [
                                  _vm._v(
                                    " " + _vm._s(_vm.$language("목록형")) + " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "q-btn",
                              {
                                attrs: {
                                  outline: _vm.scenarioViewGroup !== "C",
                                  color:
                                    _vm.scenarioViewGroup === "C"
                                      ? "indigo"
                                      : "grey",
                                  size: "11px",
                                  label: "",
                                  icon: "space_dashboard",
                                },
                                on: { click: _vm.cardView },
                              },
                              [
                                _c("q-tooltip", [
                                  _vm._v(
                                    " " + _vm._s(_vm.$language("카드형")) + " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "customArea",
                    fn: function ({ props, col }) {
                      return [
                        col.name === "item"
                          ? [
                              _c(
                                "q-item",
                                { staticClass: "card-scenario-list" },
                                [
                                  _c(
                                    "q-item-section",
                                    [
                                      _c(
                                        "q-item-label",
                                        { staticClass: "scenario-card-title" },
                                        [
                                          _vm._v(
                                            _vm._s(`${props.row["casNo"]} `)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-section",
                                    { attrs: { side: "" } },
                                    [
                                      _c("q-icon", {
                                        staticClass: "text-primary",
                                        staticStyle: { "font-size": "20px" },
                                        attrs: { name: "arrow_forward_ios" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.getDetail(props.row)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-update-btn", {
                          attrs: {
                            name: "updateBtn",
                            data: _vm.estimationUpdateBtnData,
                            btnEditable: _vm.estimationBtnEditable,
                            flagCondition: _vm.flagCondition,
                          },
                          on: { back: _vm.back },
                        }),
                        _c("c-btn", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isCompared,
                              expression: "isCompared",
                            },
                          ],
                          attrs: {
                            label: "이전평가결과조회",
                            icon: "compare_arrows",
                          },
                          on: { btnClicked: _vm.compareScenario },
                        }),
                        _vm.editable &&
                        !_vm.disabled &&
                        _vm.scenarioViewGroup === "L"
                          ? _c("c-btn", {
                              attrs: {
                                isSubmit: _vm.isSave,
                                url: _vm.saveUrl,
                                param: _vm.process.charmEstimationScenarios,
                                mappingType: "PUT",
                                label: "저장",
                                icon: "save",
                              },
                              on: {
                                beforeAction: _vm.saveScenario,
                                btnCallback: _vm.saveScenarioCallback,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _vm.scenarioViewGroup === "C"
          ? _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-6 col-md-8 col-lg-8 scenario-card",
              },
              [
                _c("q-form", { ref: "editForm" }, [
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c(
                        "c-card",
                        {
                          staticClass: "cardClassDetailForm col-6 charm",
                          attrs: { title: "노출수준 측정" },
                        },
                        [
                          _c(
                            "template",
                            { slot: "card-button" },
                            [
                              _c(
                                "q-btn-group",
                                { attrs: { outline: "" } },
                                [
                                  _c("c-btn", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.editable &&
                                          !_vm.disabled &&
                                          _vm.isCardSelect,
                                        expression:
                                          "editable&&!disabled&&isCardSelect",
                                      },
                                    ],
                                    attrs: {
                                      isSubmit: _vm.isSaveUnit,
                                      url: _vm.saveUnitUrl,
                                      param: _vm.scenario,
                                      mappingType: "PUT",
                                      label: "저장",
                                      icon: "save",
                                    },
                                    on: {
                                      beforeAction: _vm.saveScenarioUnit,
                                      btnCallback: _vm.saveScenarioUnitCallback,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("template", { slot: "card-detail" }, [
                            _c("div", { staticClass: "col-12 font" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$language("1순위")) + " "
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-4" },
                              [
                                _c("c-checkbox", {
                                  attrs: {
                                    disabled:
                                      _vm.disabled || !_vm.selectScenario,
                                    editable: _vm.editable,
                                    isFlag: true,
                                    label: "유소견자 발생여부",
                                    name: "suspectUserFlag",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.cardDataChange(
                                        "suspectUserFlag"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.scenario.suspectUserFlag,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.scenario,
                                        "suspectUserFlag",
                                        $$v
                                      )
                                    },
                                    expression: "scenario.suspectUserFlag",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "col-12 font2 mb-4" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$language("2순위 작업환경측정")) +
                                  " "
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-4" },
                              [
                                _c("c-text", {
                                  attrs: {
                                    disabled:
                                      _vm.disabled || !_vm.selectScenario,
                                    editable: _vm.editable,
                                    label: "측정치(A)",
                                    name: "measValue",
                                  },
                                  on: {
                                    dataChange: function ($event) {
                                      return _vm.cardDataChange("measValue")
                                    },
                                  },
                                  model: {
                                    value: _vm.scenario.measValue,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.scenario, "measValue", $$v)
                                    },
                                    expression: "scenario.measValue",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-4" },
                              [
                                _c("c-text", {
                                  attrs: {
                                    disabled:
                                      _vm.disabled || !_vm.selectScenario,
                                    editable: _vm.editable,
                                    label: "노출기준(B)",
                                    name: "exposureStandard",
                                  },
                                  on: {
                                    dataChange: function ($event) {
                                      return _vm.cardDataChange(
                                        "exposureStandard"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.scenario.exposureStandard,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.scenario,
                                        "exposureStandard",
                                        $$v
                                      )
                                    },
                                    expression: "scenario.exposureStandard",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-4" },
                              [
                                _c("c-text", {
                                  attrs: {
                                    editable: false,
                                    label: "A/B(%)",
                                    name: "exposureMeasValue",
                                  },
                                  model: {
                                    value: _vm.scenario.exposureMeasValue,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.scenario,
                                        "exposureMeasValue",
                                        $$v
                                      )
                                    },
                                    expression: "scenario.exposureMeasValue",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "col-12 font2 mb-4" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$language(
                                      "3순위 하루 취급량, 비산성/휘발성"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-3" },
                              [
                                _c("c-text", {
                                  attrs: {
                                    disabled:
                                      _vm.disabled || !_vm.selectScenario,
                                    editable: _vm.editable,
                                    label: "함유량",
                                    name: "limitRepval",
                                  },
                                  on: {
                                    dataChange: function ($event) {
                                      return _vm.cardDataChange("limitRepval")
                                    },
                                  },
                                  model: {
                                    value: _vm.scenario.limitRepval,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.scenario, "limitRepval", $$v)
                                    },
                                    expression: "scenario.limitRepval",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-3" },
                              [
                                _c("c-text", {
                                  attrs: {
                                    editable: false,
                                    label: "일취급량",
                                    name: "dailyVolume",
                                  },
                                  model: {
                                    value: _vm.scenario.dailyVolume,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.scenario, "dailyVolume", $$v)
                                    },
                                    expression: "scenario.dailyVolume",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-3" },
                              [
                                _c("c-text", {
                                  attrs: {
                                    editable: false,
                                    label: "단위",
                                    name: "volumeUnitName",
                                  },
                                  model: {
                                    value: _vm.scenario.volumeUnitName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.scenario,
                                        "volumeUnitName",
                                        $$v
                                      )
                                    },
                                    expression: "scenario.volumeUnitName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-3" },
                              [
                                _c("c-text", {
                                  attrs: {
                                    editable: false,
                                    label: "일취급 수준",
                                    name: "dailyLevel",
                                  },
                                  model: {
                                    value: _vm.scenario.dailyLevel,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.scenario, "dailyLevel", $$v)
                                    },
                                    expression: "scenario.dailyLevel",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-3" },
                              [
                                _c("c-select", {
                                  attrs: {
                                    type: "edit",
                                    disabled:
                                      _vm.disabled || !_vm.selectScenario,
                                    editable: _vm.editable,
                                    label: "발생형태",
                                    name: "maleficenceType",
                                    codeGroupCd: "MALEFICENCE_TYPE",
                                    itemText: "codeName",
                                    itemValue: "code",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.cardDataChange(
                                        "maleficenceType"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.scenario.maleficenceType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.scenario,
                                        "maleficenceType",
                                        $$v
                                      )
                                    },
                                    expression: "scenario.maleficenceType",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.isMaleficenceType1
                              ? _c(
                                  "div",
                                  { staticClass: "col-3" },
                                  [
                                    _c("c-select", {
                                      attrs: {
                                        type: "edit",
                                        disabled:
                                          _vm.disabled ||
                                          !_vm.selectScenario ||
                                          _vm.maleficenceTypeFlag,
                                        editable: _vm.editable,
                                        label: "비산성",
                                        name: "maleficenceGrade",
                                        codeGroupCd: "MALEFICENCE_GRADE",
                                        itemText: "codeName",
                                        itemValue: "code",
                                      },
                                      model: {
                                        value: _vm.scenario.maleficenceGrade,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.scenario,
                                            "maleficenceGrade",
                                            $$v
                                          )
                                        },
                                        expression: "scenario.maleficenceGrade",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isMaleficenceType2
                              ? _c(
                                  "div",
                                  { staticClass: "col-3" },
                                  [
                                    _c("c-text", {
                                      attrs: {
                                        disabled:
                                          _vm.disabled ||
                                          !_vm.selectScenario ||
                                          !_vm.maleficenceTypeFlag,
                                        editable: _vm.editable,
                                        label: "끓는점",
                                        name: "boilingPoint",
                                      },
                                      on: {
                                        dataChange: function ($event) {
                                          return _vm.cardDataChange(
                                            "boilingPoint"
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.scenario.boilingPoint,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.scenario,
                                            "boilingPoint",
                                            $$v
                                          )
                                        },
                                        expression: "scenario.boilingPoint",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isMaleficenceType2
                              ? _c(
                                  "div",
                                  { staticClass: "col-3" },
                                  [
                                    _c("c-text", {
                                      attrs: {
                                        editable: false,
                                        label: "휘발성",
                                        name: "volatilityGradeName",
                                      },
                                      model: {
                                        value: _vm.scenario.volatilityGradeName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.scenario,
                                            "volatilityGradeName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "scenario.volatilityGradeName",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("div", { staticClass: "col-12 font2 mb-4" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$language("최종노출수준 적용기준")
                                  ) +
                                  " "
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-3" },
                              [
                                _c("c-text", {
                                  attrs: {
                                    editable: false,
                                    label: "노출수준",
                                    name: "exposureLevelCombination",
                                  },
                                  model: {
                                    value:
                                      _vm.scenario.exposureLevelCombination,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.scenario,
                                        "exposureLevelCombination",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "scenario.exposureLevelCombination",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-4" },
                              [
                                _c("c-select", {
                                  attrs: {
                                    disabled:
                                      _vm.disabled || !_vm.selectScenario,
                                    editable: _vm.editable,
                                    label: "밀폐·환기상태",
                                    name: "closedVentilation",
                                    codeGroupCd: "CLOSED_VENTILATION",
                                    itemText: "codeName",
                                    itemValue: "code",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.cardDataChange(
                                        "closedVentilation"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.scenario.closedVentilation,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.scenario,
                                        "closedVentilation",
                                        $$v
                                      )
                                    },
                                    expression: "scenario.closedVentilation",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c(
                            "c-card",
                            {
                              staticClass: "cardClassDetailForm charm",
                              staticStyle: {
                                position: "relative",
                                top: "-3px",
                              },
                              attrs: { title: "유해성 측정" },
                            },
                            [
                              _c("template", { slot: "card-detail" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12 font3 mb-4" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$language("1순위")) + " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-6" },
                                  [
                                    _c("c-checkbox", {
                                      attrs: {
                                        disabled:
                                          _vm.disabled || !_vm.selectScenario,
                                        editable: _vm.editable,
                                        isFlag: true,
                                        label: "CMR여부",
                                        name: "cmrFlag",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.cardDataChange("cmrFlag")
                                        },
                                      },
                                      model: {
                                        value: _vm.scenario.cmrFlag,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.scenario, "cmrFlag", $$v)
                                        },
                                        expression: "scenario.cmrFlag",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-12 font4 mb-4" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$language("2순위 노출기준")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-6" },
                                  [
                                    _c("c-select", {
                                      attrs: {
                                        type: "edit",
                                        disabled:
                                          _vm.disabled || !_vm.selectScenario,
                                        editable: _vm.editable,
                                        label: "발생형태",
                                        name: "maleficenceForm",
                                        codeGroupCd: "MALEFICENCE_FORM",
                                        itemText: "codeName",
                                        itemValue: "code",
                                      },
                                      model: {
                                        value: _vm.scenario.maleficenceForm,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.scenario,
                                            "maleficenceForm",
                                            $$v
                                          )
                                        },
                                        expression: "scenario.maleficenceForm",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-6" },
                                  [
                                    _c("c-text", {
                                      attrs: {
                                        disabled:
                                          _vm.disabled || !_vm.selectScenario,
                                        editable: _vm.editable,
                                        label: "측정값",
                                        name: "maleficenceValue",
                                      },
                                      on: {
                                        dataChange: function ($event) {
                                          return _vm.cardDataChange(
                                            "maleficenceValue"
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.scenario.maleficenceValue,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.scenario,
                                            "maleficenceValue",
                                            $$v
                                          )
                                        },
                                        expression: "scenario.maleficenceValue",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-12 font4 mb-4" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$language("3순위 위험문구")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-6" },
                                  [
                                    _c("c-select", {
                                      attrs: {
                                        type: "edit",
                                        disabled:
                                          _vm.disabled || !_vm.selectScenario,
                                        editable: _vm.editable,
                                        label: "위험문구/유해·위험문구",
                                        name: "hrCodeGrade",
                                        codeGroupCd: "HR_CODE_GRADE",
                                        itemText: "codeName",
                                        itemValue: "code",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.cardDataChange(
                                            "hrCodeGrade"
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.scenario.hrCodeGrade,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.scenario,
                                            "hrCodeGrade",
                                            $$v
                                          )
                                        },
                                        expression: "scenario.hrCodeGrade",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            2
                          ),
                          _c(
                            "c-card",
                            {
                              staticClass: "cardClassDetailForm charm",
                              attrs: {
                                title: "최종 유해성 결과",
                                topClass: "topcolor-orange",
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "card-detail" },
                                [
                                  _c(
                                    "q-list",
                                    {
                                      attrs: {
                                        dense: "",
                                        bordered: "",
                                        separator: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "q-item",
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "card-risk-info-charm detail-risk",
                                            },
                                            [
                                              _c(
                                                "q-item-section",
                                                [
                                                  _c("q-item-label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$language(
                                                          "최종 노출수준"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "q-item-section",
                                                { attrs: { side: "" } },
                                                [
                                                  _c("q-item-label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.scenario
                                                          .exposureLevelFinal
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("q-separator", {
                                            attrs: { spaced: "", vertical: "" },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "card-risk-info-charm detail-risk",
                                            },
                                            [
                                              _c(
                                                "q-item-section",
                                                [
                                                  _c("q-item-label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$language("유해성")
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "q-item-section",
                                                { attrs: { side: "" } },
                                                [
                                                  _c("q-item-label", [
                                                    _vm._v(
                                                      _vm._s(_vm.scenario.toxic)
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("q-item", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "card-risk-info-charm detail-risk",
                                          },
                                          [
                                            _c(
                                              "q-item-section",
                                              [
                                                _c("q-item-label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$language(
                                                        "위험도 추정"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item-section",
                                              {
                                                staticClass:
                                                  "detail-risk-num-charm",
                                                attrs: { side: "" },
                                              },
                                              [
                                                _c("q-item-label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.scenario
                                                        .riskEstimation
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
        _c("c-win-pop", {
          ref: "winPopup",
          on: {
            onClose: (val) => _vm.evtCloseWinPopup(val),
            onRecvEvtFromWinPop: (val) => _vm.onRecvWinPop(val),
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }